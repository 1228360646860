import { PersistedClient, Persister } from '@tanstack/react-query-persist-client'

const CURRENT_CACHE_VERSION = 1

export function createWebViewPersister() {
  return {
    persistClient: async (client: PersistedClient) => {
      if (!window.ReactNativeWebView) {
        console.error('window.ReactNativeWebView is not defined')
        return
      }

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'persistClient',
          version: CURRENT_CACHE_VERSION,
          cache: JSON.stringify(client)
        })
      )
    },
    restoreClient: async () => {
      if (!window.injectedCache) {
        return
      }

      const { version, cache } = window.injectedCache

      // Handle cache versioning
      if (version !== CURRENT_CACHE_VERSION) {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'clearCache',
            version: CURRENT_CACHE_VERSION
          })
        )

        return undefined
      }

      return JSON.parse(cache) as PersistedClient
    },
    removeClient: async () => {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'clearCache',
          version: CURRENT_CACHE_VERSION
        })
      )
    }
  } as Persister
}
