import styled from 'styled-components'

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 80px - 70px - 48px);
  gap: 32px;
  padding: 0 16px 32px 16px;

  width: 100%;
  max-width: 662px;
  margin: 0 auto;

  @media (max-width: 675px) {
    min-height: calc(100vh - 80px - 40px - 48px);
  }
`

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
`

export const SkeletonSeparator = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`

export const SkeletonCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex: 0;
  width: 100%;
  flex-direction: column;
`

export const LogoContainer = styled.div`
  width: 100%;
  margin: 48px 0 24px 0;
  text-align: center;

  @media (max-width: 675px) {
    margin: 24px 0;
  }
`

export const LogoImage = styled.img`
  width: 250px;

  @media (max-width: 675px) {
    width: 200px;
  }
`

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  width: 100%;
  margin-top: -12px;
  gap: 12px;
`

interface ContentWrapperProps {
  isOpaque: boolean
}

export const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  opacity: ${({ isOpaque }) => (isOpaque ? '0.3' : '1')};
  z-index: ${({ isOpaque }) => (isOpaque ? '-1' : '0')};
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
`

interface BottomInfoWrapperProps {
  isOpaque: boolean
}

export const BottomInfoWrapper = styled.div<BottomInfoWrapperProps>`
  display: flex;
  opacity: ${({ isOpaque }) => (isOpaque ? '0.3' : '1')};
  z-index: ${({ isOpaque }) => (isOpaque ? '-1' : '0')};
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 24px 0;
`

export const FooterContainer = styled.div`
  ${({ theme }) => theme.fonts.infoLG}
  display: flex;
  flex: 0;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
  gap: 8px;
`

export const FooterLogo = styled.img`
  width: 150px;
`
