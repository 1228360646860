import { PersistedClient, Persister } from '@tanstack/react-query-persist-client'
import { get, set, del } from 'idb-keyval'

const CURRENT_CACHE_VERSION = 1

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIDBPersister(idbValidKey: IDBValidKey = 'reactQuery') {
  return {
    persistClient: async (client: PersistedClient) => {
      set(idbValidKey, JSON.stringify(client))
      set(`${String(idbValidKey)}-version`, CURRENT_CACHE_VERSION)
    },
    restoreClient: async () => {
      // Handle cache versioning
      if ((await get(`${String(idbValidKey)}-version`)) !== CURRENT_CACHE_VERSION) {
        await del(idbValidKey)
        set(`${String(idbValidKey)}-version`, CURRENT_CACHE_VERSION)
        return undefined
      }

      const dbKey = await get<string>(idbValidKey)

      if (!dbKey) {
        return undefined
      }

      return JSON.parse(dbKey) as PersistedClient
    },
    removeClient: async () => {
      await del(idbValidKey)
    }
  } as Persister
}
