import { useState } from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import * as amplitude from '@amplitude/analytics-browser'

import { A2hs } from '@/components/A2hs'
import { Chatbox } from '@/components/Chatbox/Chatbox'
import { useAppContext } from '@/context/AppContext'
import { useAddToHomescreenPrompt } from '@/hooks/useAddToHomeScreenPrompt'
import { useDeviceType } from '@/hooks/useDeviceType'

import { DefaultLayoutContainer } from './DefaultLayout.styles'

export function DefaultLayout() {
  const { isEmbedded, isPwa } = useAppContext()

  const deviceType = useDeviceType()
  const [canA2HS, promptToInstall] = useAddToHomescreenPrompt()
  const [isA2HSVisible, setIsA2HSVisible] = useState(true)

  return (
    <DefaultLayoutContainer>
      <ScrollRestoration
        getKey={(location, matches) => {
          return location.pathname
        }}
      />
      {!isEmbedded && <Chatbox />}
      {!isEmbedded && !isPwa && canA2HS && deviceType !== 'desktop' && isA2HSVisible && (
        <A2hs
          deviceType={deviceType}
          onAddClick={() => {
            amplitude.track('NEXMARKET_PWA_INSTALL')
            promptToInstall()
          }}
          onCloseClick={() => {
            amplitude.track('NEXMARKET_PWA_DECLINE')
            setIsA2HSVisible(false)
          }}
        />
      )}
      <Outlet />
    </DefaultLayoutContainer>
  )
}
