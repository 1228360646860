import { useLayoutEffect, useState } from 'react'

import NexAtlasLogo from '@assets/nexatlas-logo.svg'
import NexMarketLogo from '@assets/nexmarket-logo.svg'
import { TextField } from '@nexds/web'

import { useAppNavigate } from '@/hooks/useAppNavigate'
import { useWindowSize } from '@/hooks/useWindowSize'
import { DOCUMENT_BASE_TITLE } from '@/utils/constants'

import { SearchWrapper } from '../List/List.styles'
import {
  CenterWrapper,
  ContentWrapper,
  FooterImage,
  FooterText,
  FooterWrapper,
  Logo,
  PageContainer,
  SearchButton,
  Subtitle,
  Title,
  Wrapper
} from './Home.styles'

const Home = () => {
  const navigate = useAppNavigate()
  const { width: windowWidth } = useWindowSize()

  const [search, setSearch] = useState('')
  const [searchError, setSearchError] = useState<string | null>(null)

  useLayoutEffect(() => {
    document.title = DOCUMENT_BASE_TITLE
  }, [])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearch(e.target.value?.toUpperCase())
    setSearchError(null)
  }

  const handleOnSearch = () => {
    if (search.length === 4) {
      setSearchError(null)
      navigate(`/list?search=${search}`)
    } else {
      setSearchError('ICAO do aeródromo deve ter 4 caracteres')
    }
  }

  return (
    <PageContainer>
      <Wrapper>
        <ContentWrapper>
          <Logo src={NexMarketLogo} alt="" aria-hidden />
          <CenterWrapper>
            <Title>O maior catálogo de serviços de apoio à atividade aérea do Brasil</Title>
            <Subtitle>Encontre contatos de abastecimento, hangaragem, atendimento e muito mais.</Subtitle>
            <SearchWrapper>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  handleOnSearch()
                }}
              >
                <TextField
                  placeholder={(windowWidth ?? 1920) < 420 ? 'ICAO do aeródromo' : 'Pesquise pelo ICAO do aeródromo'}
                  helpMessage={searchError ?? undefined}
                  error={!!searchError}
                  align="center"
                  leftIcon="Search"
                  maxLength={4}
                  value={search}
                  onChange={handleOnChange}
                />
                <SearchButton label="Pesquisar" variant="filled" color="primary" size="sm" onPress={handleOnSearch} />
              </form>
            </SearchWrapper>
          </CenterWrapper>
        </ContentWrapper>
        <FooterWrapper>
          <FooterText>Desenvolvido por</FooterText>
          <a
            href="https://nexatlas.com/?utm_source=nexmarket&utm_medium=footer&utm_campaign=poweredbyNexAtlas"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterImage src={NexAtlasLogo} alt="NexAtlas" />
          </a>
        </FooterWrapper>
      </Wrapper>
    </PageContainer>
  )
}

export { Home }
