import { useEffect } from 'react'

import { getUserData } from '@/services/authService'

const Chatbox = () => {
  useEffect(() => {
    window.$crisp = []

    window.$crisp.push(['do', 'chat:show'])
    window.$crisp.push(['safe', true])
    window.CRISP_WEBSITE_ID = '0ba39795-a324-4407-ba15-745f0952b82a'

    const userData = getUserData()
    if (userData) {
      window.$crisp.push(['safe', true])
      window.$crisp.push(['set', 'user:nickname', [`${userData.name} ${userData.surname}`]])
      window.$crisp.push(['set', 'user:email', [userData.email]])
      window.$crisp.push([
        'set',
        'session:data',
        [
          [
            ['id', userData.id],
            ['segment', userData.segment]
          ]
        ]
      ])
    }

    const d = document
    const s = d.createElement('script')
    s.src = 'https://client.crisp.chat/l.js'
    s.async = true
    d.getElementsByTagName('head')[0].appendChild(s)

    return () => {
      if (window.$crisp) {
        window.$crisp.push(['do', 'chat:hide'])
      }

      if (s) {
        s?.parentNode?.removeChild(s)
      }
    }
  }, [])

  return null
}

export { Chatbox }
