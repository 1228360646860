import styled from 'styled-components'

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 320px;
  gap: 24px;
  background: transparent;
  margin-bottom: 16px;
`

export const InfoText = styled.div`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.neutralL5};
  text-align: center;
`
