export interface UserData {
  id: number
  email: string
  name: string
  surname: string
  segment: string
}

export const getUserData = () => {
  const nexatlasNapCookie = document.cookie.match(/_nap=([^;]+)/)
  const nexatlasNap = nexatlasNapCookie ? nexatlasNapCookie[1] : ''

  let userData: UserData | null = null
  if (nexatlasNap) {
    const decodedNap = decodeURIComponent(nexatlasNap)
    userData = JSON.parse(decodedNap)
  }

  return userData
}
