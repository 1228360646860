import { Search, Warning } from '@nexds/web'

import { InfoContainer, InfoText } from './Info.styles'

interface InfoProps {
  icon?: 'warning' | 'search'
  infoText: string
}

export function Info(props: InfoProps) {
  const { icon, infoText } = props

  return (
    <InfoContainer>
      {icon === 'warning' && <Warning color="#fff" size={40} />}
      {icon === 'search' && <Search color="#fff" size={36} />}
      <InfoText>{infoText}</InfoText>
    </InfoContainer>
  )
}
