/// <reference types="vite-plugin-pwa/client" />

import ReactDOM from 'react-dom/client'

import { Buffer } from 'buffer'
import { Stream } from 'stream'
import { registerSW } from 'virtual:pwa-register'

import { App } from './App'
import { initTracking } from './services/trackingService'

globalThis.Buffer = Buffer
// @ts-expect-error - globalThis.stream is a valid property
globalThis.stream = Stream

declare global {
  interface Window {
    $crisp: any
    CRISP_WEBSITE_ID: string
    gtag: any
    fbq: any

    // React Native Webview
    isRNWebview: boolean | undefined
    injectedCache: any
    ReactNativeWebView:
      | {
          postMessage: (data: string) => void
        }
      | undefined
  }
}

const swReloadInterval = 1000 * 60 * 60 * 1 // 1 hour
registerSW({
  immediate: true,
  onRegisteredSW(swUrl, r) {
    r &&
      setInterval(async () => {
        if (!(!r.installing && navigator)) return

        if ('connection' in navigator && !navigator.onLine) return

        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            cache: 'no-store',
            'cache-control': 'no-cache'
          }
        })

        if (resp?.status === 200) await r.update()
      }, swReloadInterval)
  }
})

if (process.env.NODE_ENV !== 'development') {
  initTracking()
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />)
