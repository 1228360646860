import { useEffect, useLayoutEffect } from 'react'
import { useParams } from 'react-router-dom'

import * as amplitude from '@amplitude/analytics-browser'
import { Header, RectangleSkeleton } from '@nexds/web'
import { useQuery } from '@tanstack/react-query'

import { searchServices } from '@/services/searchService'

import { Info } from '@/components/Info'
import { useAppContext } from '@/context/AppContext'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { DOCUMENT_BASE_TITLE } from '@/utils/constants'

import { ContentIframe, ContentWrapper, HeaderWrapper, InfoWrapper, SkeletonWrapper } from './Partners.styles'

export function Partners() {
  const { id } = useParams()
  const { appReady, isOnline } = useAppContext()
  const navigate = useAppNavigate()

  // Icao is the last part of the id, separated by a dash
  const idSplit = (id ?? '').split('-')
  const icao = idSplit[idSplit.length - 1].toUpperCase()

  const { fetchStatus, data: searchResults } = useQuery({
    queryKey: ['services', icao],
    queryFn: async () => searchServices(icao)
  })

  useEffect(() => {
    amplitude.track('NEXMARKET_SERVICE_ACCESSED', { service: id })
  }, [])

  useEffect(() => {
    if (!isOnline) {
      navigate(`/service/${id ?? ''}`)
    }
  }, [id, navigate])

  const service = searchResults?.find((service) => service.id === id)
  const isLoading = fetchStatus === 'fetching'

  useLayoutEffect(() => {
    document.title = service?.name ? `${service.name} - ${service.icao} - NexMarket` : DOCUMENT_BASE_TITLE
  }, [service])

  const handleReturn = () => {
    navigate(`/list?search=${icao}`)
  }

  return (
    <ContentWrapper>
      <HeaderWrapper>
        <Header title={service?.name ?? ''} wrapperWidth={630} onReturn={handleReturn} />
      </HeaderWrapper>
      {isLoading && !service && (
        <SkeletonWrapper>
          <RectangleSkeleton height={500} />
        </SkeletonWrapper>
      )}
      {service && <ContentIframe src={service?.pageUrl ?? ''}></ContentIframe>}
      {fetchStatus === 'paused' && appReady && !service && (
        <InfoWrapper>
          <Info icon="warning" infoText="Ocorreu um erro na sua busca, verifique a conexão com a internet." />
        </InfoWrapper>
      )}

      {fetchStatus === 'idle' && appReady && !service && (
        <InfoWrapper>
          <Info icon="warning" infoText="Não temos informação sobre este serviço. Realize outra busca." />
        </InfoWrapper>
      )}
    </ContentWrapper>
  )
}
