export function capitalizeFirstLetterOfEachWord(str: string) {
  const words = str.split(' ')
  const capitalizedWords = words.map((word) => {
    if (word.length <= 2) return word
    const firstLetter = word.charAt(0).toUpperCase()
    const rest = word.slice(1).toLowerCase()
    return `${firstLetter}${rest}`
  })

  return capitalizedWords.join(' ')
}
