import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'

import axios from 'axios'
import qs from 'qs'

import { Splash } from '@/components/Splash'

interface AppContextProps {
  appReady: boolean
  isOnline: boolean
  isEmbedded: boolean | null
  isPwa: boolean | null
}

const DEFAULT_CONTEXT: AppContextProps = {
  appReady: false,
  isOnline: false,
  isEmbedded: null,
  isPwa: null
}

const AppContext = React.createContext(DEFAULT_CONTEXT)

interface AppContextProviderProps {
  children: React.ReactElement
  isAppReady: boolean
}

const AppContextProvider: React.FC<AppContextProviderProps> = (props: AppContextProviderProps) => {
  const { children, isAppReady } = props

  const [appReady, setAppReady] = useState(isAppReady)
  const [isOnline, setIsOnline] = useState(DEFAULT_CONTEXT.isOnline)
  const [isEmbedded, setIsEmbedded] = useState(DEFAULT_CONTEXT.isEmbedded)
  const [isPwa, setIsPwa] = useState(DEFAULT_CONTEXT.isPwa)

  useEffect(() => {
    setAppReady(isAppReady)
  }, [isAppReady])

  useLayoutEffect(() => {
    const existingQueries = qs.parse(location.search, {
      ignoreQueryPrefix: true
    })

    setIsEmbedded(existingQueries.embedded === 'true')
    setIsPwa(window.matchMedia('(display-mode: standalone)').matches || existingQueries.source === 'pwa')
  }, [])

  useLayoutEffect(() => {
    const ONLINE_TIME_CHECK_INTERVAL = 60000
    const OFFLINE_TIME_CHECK_INTERVAL = 3000

    const checkIfOnline = async (): Promise<boolean> => {
      try {
        const online = await axios.get('https://nexmarket-api.nexatlas.com/', {
          timeout: 5000,
          headers: {
            'Cache-Control': 'no-cache'
          },
          params: {
            t: Date.now()
          },
          validateStatus: () => true
        })

        return online.status >= 200 && online.status < 400
      } catch (error) {
        return false
      }
    }

    const handleChangeStatus = async () => {
      const onlineCheck = await checkIfOnline()

      setIsOnline((lastState) => {
        if (onlineCheck === lastState) return lastState

        console.log(`[AppContext] Online status changed to ${onlineCheck ? 'online' : 'offline'}`)
        return onlineCheck
      })

      if (onlineCheck) {
        setTimeout(handleChangeStatus, ONLINE_TIME_CHECK_INTERVAL)
      } else {
        setTimeout(handleChangeStatus, OFFLINE_TIME_CHECK_INTERVAL)
      }
    }

    // First check if online
    handleChangeStatus()

    // Check if online on load
    window.addEventListener('online', handleChangeStatus)
    window.addEventListener('offline', handleChangeStatus)

    // Listen to react-native-webview events
    if (window.isRNWebview && window.ReactNativeWebView) {
      document.addEventListener('message', (event) => {
        // @ts-expect-error
        const message = event.data as string
        if (!message) return

        const messageData = JSON.parse(message)

        console.log(`[AppContext] Got message from RN Webview: ${message}`)

        if (messageData.type === 'setOnlineStatus') {
          handleChangeStatus()
        }
      })
    }

    return () => {
      window.removeEventListener('online', handleChangeStatus)
      window.removeEventListener('offline', handleChangeStatus)
    }
  }, [])

  if (isEmbedded === null || isPwa === null) return <Splash />

  return (
    <AppContext.Provider
      value={{
        appReady,
        isOnline,
        isEmbedded,
        isPwa
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

const useAppContext = (): AppContextProps => {
  const context = useContext(AppContext)

  if (context === undefined) {
    throw new Error('useAppContext must be used within a AppContextProvider')
  }

  return context
}

export { AppContextProvider, useAppContext }
