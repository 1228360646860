import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0;
  width: 100%;
`

export const ContentIframe = styled.iframe`
  width: 100%;
  flex: 1;
  border: none;
  overflow: hidden;
  display: block;
  background-color: ${({ theme }) => theme.colors.secondaryD1};
`

export const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 24px 0;
`

export const SkeletonWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 24px;
`
