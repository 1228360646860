import axios from 'axios'

import { Service } from '@/domain/models/Service'

const axiosClient = axios.create({
  baseURL: 'https://nexmarket-api.nexatlas.com',
  headers: {
    'Content-Type': 'application/json'
  }
})

interface SearchServicesResult {
  services: Service[]
}

export const searchServices = async (icao: string, filter?: string[]): Promise<Service[]> => {
  const webResult = await axiosClient.get<SearchServicesResult>('/services', {
    params: {
      icao: icao.toUpperCase(),
      service: filter?.join(',') ?? undefined
    },
    timeout: 5000
  })

  if (webResult.status !== 200) {
    throw new Error('Error on search services')
  }

  return webResult.data.services
}

interface LoadServiceResult {
  service: Service
}

export const loadService = async (id: string): Promise<Service | null> => {
  try {
    const webResult = await axiosClient.get<LoadServiceResult>(`/service/${id}`, {
      timeout: 8000
    })

    if (webResult.status !== 200) {
      return null
    }

    return webResult.data.service
  } catch (error) {
    return null
  }
}
