import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'

import { List } from '@pages/List'
import { NonPartners } from '@pages/NonPartners'
import { Partners } from '@pages/Partners'

import { DefaultLayout } from '@/layouts/DefaultLayout'
import { Home } from '@/pages/Home'

const router = createBrowserRouter([
  {
    path: '/',
    element: <DefaultLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/list',
        element: <List />
      },
      {
        path: '/service/:id',
        element: <NonPartners />
      },
      {
        path: '/partner/:id',
        element: <Partners />
      },
      {
        path: '*',
        element: <Navigate to="/" />
      }
    ]
  }
])

export function Router() {
  return <RouterProvider router={router} />
}
