import { useEffect, useState } from 'react'

export type DeviceType = 'desktop' | 'mobile' | 'tablet'

const useDeviceType = () => {
  const initialDeviceType = () => {
    if (window.innerWidth < 768) {
      return 'mobile'
    } else if (window.innerWidth < 1024) {
      return 'tablet'
    } else {
      return 'desktop'
    }
  }

  const [deviceType, setDeviceType] = useState<DeviceType>(initialDeviceType())

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setDeviceType('mobile')
      } else if (window.innerWidth < 1024) {
        setDeviceType('tablet')
      } else {
        setDeviceType('desktop')
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return deviceType
}

export { useDeviceType }
