import placeholderLogo from '@assets/placeholder-logo.svg'
import { ReactComponent as VerifiedIcon } from '@assets/verified.svg'
import { ChevronRight } from '@nexds/web'
import { useTheme } from 'styled-components'

import { capitalizeFirstLetterOfEachWord } from '@/utils/formatString'

import { CardContainer, Logo, Services, Title, Info, Tag, OpenIndicator } from './Card.styles'

interface CardProps {
  logoUrl?: string
  name: string
  services?: string[]
  verified?: boolean
  onPress?: () => void
}

export function Card(props: CardProps) {
  const { logoUrl, name, services = [], verified = false, onPress } = props
  const theme = useTheme()

  return (
    <CardContainer onClick={onPress}>
      <Logo src={logoUrl ?? placeholderLogo} alt="logo" />
      <Info>
        <Title>
          <h2>{name}</h2> {verified && <VerifiedIcon />}
        </Title>
        <Services>
          {services.map(
            (service, index) => index < 4 && <Tag key={service}>{capitalizeFirstLetterOfEachWord(service)}</Tag>
          )}
          {services.length > 4 && <span>+{services.length - 4}</span>}
        </Services>
      </Info>
      <OpenIndicator>
        <ChevronRight size="sm" color={theme.colors.neutralL5} />
      </OpenIndicator>
    </CardContainer>
  )
}
