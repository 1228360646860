const checkIfBot = (): boolean => {
  const agent = window.navigator.userAgent

  const botUserAgentsArray = ['googlebot', 'bingbot', 'linkedinbot', 'mediapartners-google', 'lighthouse', 'insights']

  let isBotUserAgent = false
  for (let j = 0; j < botUserAgentsArray.length; j++) {
    if (agent.toLowerCase().includes(botUserAgentsArray[j].toLowerCase())) {
      isBotUserAgent = true
      break
    }
  }

  return isBotUserAgent
}

export { checkIfBot }
