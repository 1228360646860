import styled from 'styled-components'

export const SplashContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;

  background-color: ${({ theme }) => theme.colors.secondaryD1};
`

export const SplashLogo = styled.img`
  width: 100%;
  max-width: 300px;

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }

    50% {
      transform: scale(0.98);
      opacity: 0.3;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  animation: pulse 2s infinite ease-in-out;
`
