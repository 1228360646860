import PwaIcon from '@/assets/pwa-icon.svg'
import { DeviceType } from '@/hooks/useDeviceType'

import { A2hsWrapper, AddButton, ButtonWrapper, CloseButton, TitleIcon, TitleText, TitleWrapper } from './A2hs.styles'

interface A2hsProps {
  deviceType: DeviceType
  onAddClick: () => void
  onCloseClick: () => void
}

const A2hs: React.FC<A2hsProps> = (props: A2hsProps) => {
  const { deviceType, onAddClick, onCloseClick } = props

  const translationMap = {
    mobile: 'celular',
    desktop: 'computador',
    tablet: 'tablet'
  }

  return (
    <A2hsWrapper>
      <TitleWrapper>
        <TitleIcon src={PwaIcon} />
        <TitleText>
          Adicione o NexMarket à tela inicial do seu {translationMap[deviceType]}.
        </TitleText>
      </TitleWrapper>
      <ButtonWrapper>
        <AddButton variant="filled" color="primary" label="Adicionar" size="sm" onPress={onAddClick} />
        <CloseButton variant="outline" color="secondary" label="Agora não" size="sm" onPress={onCloseClick} />
      </ButtonWrapper>
    </A2hsWrapper>
  )
}

export { A2hs }
