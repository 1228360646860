import styled from 'styled-components'

export const NonPartnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 16px 32px 16px;
  background-color: ${({ theme }) => theme.colors.secondaryD1};

  width: 100%;
  max-width: 662px;
  margin: 0 auto;
`

export const Title = styled.div`
  margin-top: 8px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.25rem;
`

export const Name = styled.h1`
  ${({ theme }) => theme.fonts.titleMD};
  color: ${({ theme }) => theme.colors.neutralL5};
`

export const Icao = styled.span`
  ${({ theme }) => theme.fonts.infoSM};
  color: ${({ theme }) => theme.colors.neutralL5};
`

export const Services = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 4px;
`

export const Tag = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 1.125rem;
  background-color: ${({ theme }) => theme.colors.secondaryL2};
  color: ${({ theme }) => theme.colors.neutralL4};
  padding: 0.25rem 0.625rem;
  border-radius: 30px;
  ${({ theme }) => theme.fonts.infoXS};
`

export const Session = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.25rem;
  color: ${({ theme }) => theme.colors.neutralL5};
  margin: 12px 0;
`

export const SessionTitle = styled.h1`
  display: block;
  ${({ theme }) => theme.fonts.titleXS};
`

export const ContactInfo = styled.span`
  display: block;
  ${({ theme }) => theme.fonts.textSM};

  & > a {
    color: ${({ theme }) => theme.colors.neutralL5};
    text-decoration: none;
  }
`

export const ContactLink = styled.a`
  color: ${({ theme }) => theme.colors.neutralL5};

`

export const Description = styled.span`
  ${({ theme }) => theme.fonts.textSM};
  color: ${({ theme }) => theme.colors.neutralL5};
  margin: 0;
  text-align: left;
`

export const Alert = styled.i`
  ${({ theme }) => theme.fonts.textSM};
  color: ${({ theme }) => theme.colors.neutralL5};
  text-align: left;
`

export const InfoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 24px 0;
`

export const SkeletonSeparator = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
`

export const SkeletonSession = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`

export const SkeletonHorizontal = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 12px;
  width: 100%;
`

export const ModalPhoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;

  padding: 18px 24px;
`
