import { PropsWithChildren, useEffect, useState } from 'react'

import { Info } from '@nexds/web'
import { useTheme } from 'styled-components'

import { HelperButton, SeparatorContainer, Title, HelperTooltip } from './Separator.styles'

interface SeparatorProps extends PropsWithChildren {
  title: string
  helperText?: string
  helperLink?: string
  helperLinkText?: string
  helperLinkOnClick?: () => void
}

export function Separator(props: SeparatorProps) {
  const { title, helperText, helperLink, helperLinkText, helperLinkOnClick, children } = props
  const theme = useTheme()

  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    const closeTooltip = () => setShowTooltip(false)
    document.addEventListener('click', closeTooltip)

    return () => {
      document.removeEventListener('click', closeTooltip)
    }
  }, [])

  return (
    <SeparatorContainer>
      <Title>
        <h3>{title}</h3>
        <HelperButton
          onClick={(event) => {
            event.stopPropagation()
            setShowTooltip(!showTooltip)
          }}
          disabled={!helperLink && !helperText}
        >
          <Info size={16} color={theme.colors.neutralL4} />
          {showTooltip && (
            <HelperTooltip>
              {helperText}
              <br />
              <br />
              <a href={helperLink} target="_blank" rel="noreferrer" onClick={() => helperLinkOnClick?.()}>
                {helperLinkText ?? helperLink}
              </a>
            </HelperTooltip>
          )}
        </HelperButton>
      </Title>
      {children}
    </SeparatorContainer>
  )
}
