import { useLayoutEffect, useState } from 'react'

import { ThemeProvider } from '@nexds/web'
import { Router } from '@router/Router'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Persister, persistQueryClient } from '@tanstack/react-query-persist-client'
import { GlobalStyle } from '@theme/globalstyles'
import { compress, decompress } from 'lz-string'

import { AppContextProvider } from './context/AppContext'
import { createIDBPersister } from './infra/idbPersister'
import { createWebViewPersister } from './infra/webviewPersister'
import { ReactQueryOnlineService } from './services/reactQueryOnlineService'
import { checkIfBot } from './utils/ckeckIfBot'

import './fonts.css'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      networkMode: 'online'
    }
  }
})

export function App() {
  const [appReady, setAppReady] = useState(false)

  useLayoutEffect(() => {
    const runPersister = async (persister: Persister) => {
      persistQueryClient({
        queryClient,
        persister,
        maxAge: Infinity
      })
    }

    const maxReadyTimeout = setTimeout(() => {
      setAppReady(true)
    }, 5000)

    // Check if is a bot. Don't persist data for bots
    if (checkIfBot()) {
      clearTimeout(maxReadyTimeout)
      setAppReady(true)
      return
    }

    // Check if is a RN Webview and use the RN Webview persister
    if (window.isRNWebview) {
      console.log('[Feature Check] Running on RN Webview')
      runPersister(createWebViewPersister())
      clearTimeout(maxReadyTimeout)
      setAppReady(true)
      return
    }

    // Check if the browser supports IndexedDB and use the IDB persister
    if (window.indexedDB) {
      console.log('[Feature Check] IndexedDb support')
      runPersister(createIDBPersister('search-cache'))
      clearTimeout(maxReadyTimeout)

      // IndexedDb is async and takes a little time to load
      setTimeout(() => {
        setAppReady(true)
      }, 300)
      return
    }

    // Fallback to localStorage
    console.log('[Feature Check] No indexedDb support')
    runPersister(
      createSyncStoragePersister({
        storage: window.localStorage,
        serialize: (data) => compress(JSON.stringify(data)),
        deserialize: (data) => JSON.parse(decompress(data))
      })
    )
    clearTimeout(maxReadyTimeout)
    setAppReady(true)
  }, [])

  return (
    <ThemeProvider theme="dark">
      <AppContextProvider isAppReady={appReady}>
        <QueryClientProvider client={queryClient}>
          <GlobalStyle />
          <Router />
          <ReactQueryOnlineService />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AppContextProvider>
    </ThemeProvider>
  )
}
