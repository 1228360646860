import * as amplitude from '@amplitude/analytics-browser'
import { BrowserTracing } from '@sentry/browser'
import * as Sentry from '@sentry/react'

import { getUserData } from './authService'

export const initTracking = () => {
  const userData = getUserData()

  if (userData) {
    console.log('[NexAtlas NAP]', userData)
  }

  /*
   * SENTRY
   */
  Sentry.init({
    dsn: 'https://95bd808051d245b788036cb4f3ecf664@sentry.nexatlas.com/13',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2
  })

  /*
   * AMPLITUDE
   */

  amplitude.init('beb00c03c09f2ab2332e93b99f65d55a', String(userData?.id), { minIdLength: 1 })

  if (userData) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: String(userData?.id),
        email: userData?.email,
        segment: userData?.segment
      })
    })

    amplitude.setUserId(String(userData?.id))
  }

  /*
   * GOOGLE TAG
   */
  const initGoogleTag = () => {
    if (!window.gtag) return

    window.gtag('js', new Date())

    if (userData) {
      window.gtag('config', 'G-VENWGLQHG3', {
        user_id: userData.id
      })

      window.gtag('set', 'user_properties', {
        segment: userData.segment
      })
    } else {
      window.gtag('config', 'G-VENWGLQHG3')
    }
  }

  initGoogleTag()

  /*
   * FACEBOOK PIXEL
   */

  const initFbPixel = async () => {
    if (!window.fbq) return

    if (userData) {
      window.fbq('init', '348887454047377', {
        em: userData.email,
        fn: userData.name,
        ln: userData.surname,
        external_id: String(userData.id)
      })
    } else {
      window.fbq('init', '348887454047377')
    }

    window.fbq('track', 'PageView')
  }

  initFbPixel()
}
