import NexMarketLogo from '@assets/nexmarket-logo.svg'

import { SplashContainer, SplashLogo } from './Splash.styles'

const Splash = () => {
  return (
    <SplashContainer>
      <SplashLogo src={NexMarketLogo} alt="" aria-hidden />
    </SplashContainer>
  )
}

export { Splash }
