import { useEffect } from 'react'

import { onlineManager } from '@tanstack/react-query'

import { useAppContext } from '@/context/AppContext'

const ReactQueryOnlineService = () => {
  const { isOnline } = useAppContext()

  useEffect(() => {
    onlineManager.setOnline(isOnline)
  }, [isOnline])

  return null
}

export { ReactQueryOnlineService }
