import { useEffect, useState, useMemo, useCallback, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'

import * as amplitude from '@amplitude/analytics-browser'
import NexAtlasLogo from '@assets/nexatlas-logo.svg'
import NexMarketLogo from '@assets/nexmarket-logo.svg'
import { Card } from '@components/Card'
import { Info } from '@components/Info'
import { Separator } from '@components/Separator'
import { Button, TextField, Select, SelectItem, Header, RectangleSkeleton } from '@nexds/web'
import { useQuery } from '@tanstack/react-query'
import QueryString from 'qs'

import { searchServices } from '@/services/searchService'

import { useAppContext } from '@/context/AppContext'
import { useAppNavigate } from '@/hooks/useAppNavigate'
import { useQueryState } from '@/hooks/useQueryState'
import { useWindowSize } from '@/hooks/useWindowSize'
import { DOCUMENT_BASE_TITLE } from '@/utils/constants'
import { capitalizeFirstLetterOfEachWord } from '@/utils/formatString'

import {
  BottomInfoWrapper,
  ContentWrapper,
  FooterContainer,
  FooterLogo,
  HeaderWrapper,
  HomeContainer,
  InfoWrapper,
  LogoContainer,
  LogoImage,
  SearchWrapper,
  SkeletonCardsWrapper,
  SkeletonSeparator,
  SkeletonWrapper
} from './List.styles'

export function List() {
  const { appReady, isEmbedded, isOnline } = useAppContext()

  const navigate = useAppNavigate()
  const { width: windowWidth } = useWindowSize()

  const existingQueries = QueryString.parse(location.search, {
    ignoreQueryPrefix: true
  })

  const [icaoSearchValue, setIcaoSearchValue] = !isEmbedded
    ? useQueryState<string>(
        'search',
        (value) => value.toUpperCase(),
        (value) => (value && value.length > 0 ? value : null)
      )
    : useState<string>((existingQueries.search as string) ?? '')

  const [selectedServices, setSelectedServices] = !isEmbedded
    ? useQueryState<string[]>(
        'filter',
        (value) => (value ? value.split(',') : []),
        (value) => (value && value.length > 0 ? value.join(',') : null)
      )
    : useState<string[]>([])

  const [serviceList, setServiceList] = useState<string[] | null>(null)
  const [isFilterOpen, setIsFilterOpen] = useState(false)

  const { fetchStatus, data: searchResults } = useQuery({
    queryKey: ['services', icaoSearchValue],
    queryFn: async () => {
      return searchServices(icaoSearchValue)
    },
    enabled: (icaoSearchValue && icaoSearchValue.length === 4) || false
  })

  useLayoutEffect(() => {
    document.title =
      icaoSearchValue && icaoSearchValue.length === 4 ? `${icaoSearchValue} - NexMarket` : DOCUMENT_BASE_TITLE
  }, [icaoSearchValue])

  useEffect(() => {
    amplitude.track('NEXMARKET_LIST_ACCESSED')
  }, [])

  useEffect(() => {
    if (icaoSearchValue && icaoSearchValue.length === 4) {
      amplitude.track('NEXMARKET_LIST_SEARCH_CHANGED', { icao: icaoSearchValue })
    }
  }, [icaoSearchValue])

  useEffect(() => {
    if (searchResults) {
      const services = searchResults
        .map((partner) => partner.services)
        .flat()
        .map((service) => service.toLowerCase().trim())

      const uniqueServices = [...new Set(services)]

      setServiceList(uniqueServices)
    } else {
      setServiceList(null)
    }
  }, [searchResults])

  useEffect(() => {
    if (selectedServices.length > 0) {
      amplitude.track('NEXMARKET_LIST-FILTER_SELECTED', { services: selectedServices })
    }
  }, [selectedServices])

  const [verifiedPartners, unverifiedPartners] = useMemo(() => {
    if (!searchResults) return [[], []]

    let filteredServices = searchResults

    if (selectedServices.length > 0) {
      filteredServices = searchResults.filter((service) =>
        service.services.some((service) => selectedServices.includes(service.toLowerCase().trim()))
      )
    }

    return [
      filteredServices.filter((service) => service.premium),
      filteredServices.filter((service) => !service.premium)
    ]
  }, [searchResults, selectedServices])

  const handleNavigateToPartnerPage = useCallback(
    (partnerSlug: string, partnerVerified: boolean) => {
      navigate(`/${isOnline && partnerVerified ? 'partner' : 'service'}/${partnerSlug}`)
    },
    [isOnline]
  )

  const isLoading = fetchStatus === 'fetching'

  return (
    <>
      {isEmbedded && <Header title="Serviços" wrapperWidth={630} />}
      {!isEmbedded && (
        <LogoContainer>
          <Link to="/">
            <LogoImage src={NexMarketLogo} alt="" aria-hidden />
          </Link>
        </LogoContainer>
      )}
      <HomeContainer>
        <HeaderWrapper>
          <SearchWrapper>
            <TextField
              rightIcon="Search"
              placeholder={(windowWidth ?? 1920) < 400 ? 'ICAO do aeródromo' : 'Pesquise pelo ICAO do aeródromo'}
              helpGutter={false}
              value={icaoSearchValue}
              onFocus={() => setIsFilterOpen(false)}
              onChange={(event) => {
                if (event.target.value.length > 4) return
                setIcaoSearchValue(event.target.value?.toUpperCase())
              }}
            />
            {isLoading && !searchResults && <RectangleSkeleton width={null} height={48} />}
            {searchResults && searchResults.length > 0 && (
              <Select
                placeholder="Filtrar serviços"
                multiple
                maxBoxRows={1}
                boxScrollDirection="horizontal"
                leftIcon="Funnel"
                helpGutter={false}
                onFocus={() => setIsFilterOpen(true)}
                onBlur={() => setIsFilterOpen(false)}
                onChange={setSelectedServices}
              >
                {serviceList ? (
                  serviceList?.map((service) => (
                    <SelectItem key={service} value={service} label={capitalizeFirstLetterOfEachWord(service)} />
                  ))
                ) : (
                  <SelectItem value={'null'} label={'Carregando...'} />
                )}
              </Select>
            )}
          </SearchWrapper>
        </HeaderWrapper>
        {isLoading && (!searchResults || searchResults?.length === 0) && (
          <SkeletonWrapper>
            <SkeletonSeparator>
              <RectangleSkeleton width={150} height={18} />
              <SkeletonCardsWrapper>
                {[...Array(2)].map((_, index) => (
                  <RectangleSkeleton key={index} width={null} height={128} />
                ))}
              </SkeletonCardsWrapper>
            </SkeletonSeparator>
            <SkeletonSeparator>
              <RectangleSkeleton width={80} height={18} />
              <SkeletonCardsWrapper>
                {[...Array(2)].map((_, index) => (
                  <RectangleSkeleton key={index} width={null} height={128} />
                ))}
              </SkeletonCardsWrapper>
            </SkeletonSeparator>
          </SkeletonWrapper>
        )}
        {searchResults && searchResults.length > 0 && (
          <>
            <ContentWrapper isOpaque={isFilterOpen}>
              <Separator
                title="PARCEIROS VERIFICADOS"
                helperText="Parceiros verificados são empresas que, em conjunto com a NexAtlas, garantem informações atualizadas e completas para você tomar as melhores decisões de contratação."
                helperLink="https://nexatlas.com/nexmarket-verificado/"
                helperLinkText="Saiba como se tornar verificado"
                helperLinkOnClick={() => {
                  amplitude.track('NEXMARKET_LIST_BE-VERIFIED-HELP_CLICKED', { icao: icaoSearchValue })
                }}
              >
                {verifiedPartners.length > 0 ? (
                  verifiedPartners.map((partnerData, index) => (
                    <Card
                      key={`${partnerData.id}-${index}`}
                      logoUrl={isOnline ? partnerData.logoUrl : undefined}
                      name={partnerData.name}
                      services={partnerData.services}
                      verified={partnerData.premium}
                      onPress={() => handleNavigateToPartnerPage(partnerData.id, partnerData.premium)}
                    />
                  ))
                ) : (
                  <InfoWrapper>
                    <Info
                      infoText={`Não há parceiros verificados nessa localidade${
                        selectedServices && selectedServices.length > 0 ? ' que possuem este serviço' : ''
                      }`}
                    />
                  </InfoWrapper>
                )}
              </Separator>
              {unverifiedPartners.length > 0 && (
                <Separator
                  title="OUTROS"
                  helperText="As informações listadas nessa categoria foram obtidas através da colaboração de pilotos da NexAtlas. Dado o volume de informações, não conseguimos garantir que os dados estejam completos ou atualizados."
                >
                  {unverifiedPartners.map((partnerData, index) => (
                    <Card
                      key={`${partnerData.id}-${index}`}
                      logoUrl={isOnline ? partnerData.logoUrl : undefined}
                      name={partnerData.name}
                      services={partnerData.services}
                      verified={partnerData.premium}
                      onPress={() => handleNavigateToPartnerPage(partnerData.id, partnerData.premium)}
                    />
                  ))}
                </Separator>
              )}
            </ContentWrapper>
            <BottomInfoWrapper isOpaque={isFilterOpen}>
              <Info infoText="Conhece outros fornecedores? Ajude outros pilotos sugerindo serviços." />
              <Button
                label="Sugerir serviço"
                variant="filled"
                color="secondary"
                size="sm"
                onPress={() => {
                  amplitude.track('NEXMARKET_LIST_SERVICE-SUGGESTION_CLICKED', { icao: icaoSearchValue })
                  window.open('https://forms.gle/4Wv1UY4hkuZygnfn9', '_blank')
                }}
              />
            </BottomInfoWrapper>
          </>
        )}
        {!isLoading && !searchResults && fetchStatus !== 'paused' && (
          <InfoWrapper>
            <Info
              icon="search"
              infoText="Pesquise por um aeródromo para exibir as informações de prestadores de serviço na localidade."
            />
          </InfoWrapper>
        )}
        {searchResults && appReady && searchResults.length === 0 && fetchStatus === 'idle' && (
          <InfoWrapper>
            <Info
              icon="warning"
              infoText="Ainda não temos informações de prestadores de serviços nesse aeródromo. Gostaria de sugerir algum fornecedor?"
            />
            <Button
              label="Sugerir serviço"
              variant="filled"
              color="secondary"
              size="sm"
              onPress={() => {
                amplitude.track('NEXMARKET_LIST_SUGGESTION', { icao: icaoSearchValue })
                window.open('https://forms.gle/4Wv1UY4hkuZygnfn9', '_blank')
              }}
            />
          </InfoWrapper>
        )}
        {fetchStatus === 'paused' && appReady && (!searchResults || searchResults.length === 0) && (
          <InfoWrapper>
            <Info icon="warning" infoText="Ocorreu um erro na sua busca, verifique a conexão com a internet." />
          </InfoWrapper>
        )}
      </HomeContainer>
      {!isEmbedded && (
        <FooterContainer>
          Desenvolvido por
          <a
            href="https://nexatlas.com/?utm_source=nexmarket&utm_medium=footer&utm_campaign=poweredbyNexAtlas"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FooterLogo src={NexAtlasLogo} alt="NexAtlas" />
          </a>
        </FooterContainer>
      )}
    </>
  )
}
