import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 128px;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;
  border: none;
  padding: 1.5rem 0 1.5rem 1rem;
  margin-top: 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryL1};
  }
`

export const Logo = styled.img`
  width: 100%;
  max-width: 80px;
  height: 100%;
  max-height: 80px;
  border-radius: 9px;
  margin-right: 1.25rem;
  object-fit: contain;

  @media (min-width: 675px) {
    max-width: 80px;
    max-height: 80px;
  }

  @media (min-width: 540px) and (max-width: 675px) {
    max-width: 70px;
    max-height: 70px;
  }

  @media (min-width: 340px) and (max-width: 540px) {
    max-width: 60px;
    max-height: 60px;
  }

  @media (max-width: 340px) {
    max-width: 50px;
    max-height: 50px;
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
  height: 100%;
  overflow: hidden;
  flex-wrap: nowrap;
`

export const Title = styled.span`
  width: 100%;
  display: flex;
  margin: 0;

  & h2 {
    ${({ theme }) => theme.fonts.titleXS};
    color: ${({ theme }) => theme.colors.neutralL5};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 2px 0;
  }

  & svg {
    min-width: 12px;
    min-height: 12px;
    margin-left: 4px;
    align-self: center;
  }
`

export const Services = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;

  & span {
    ${({ theme }) => theme.fonts.infoXS};
    color: ${({ theme }) => theme.colors.neutralL4};
  }
`

export const Tag = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  height: 1.125rem;
  border-radius: 30px;
  padding: 0.25rem 0.625rem;

  color: ${({ theme }) => theme.colors.neutralL5};
  background-color: ${({ theme }) => theme.colors.secondaryL2};
  ${({ theme }) => theme.fonts.infoXS};

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
export const OpenIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  height: 100%;
  width: 100%;
  max-width: 35px;
  margin-left: 16px;
  border: none;
`
