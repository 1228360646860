import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', Helvetica, Arial, Roboto, Verdana, Tahoma, Geneva, 'DejaVu Sans', 'Bitstream Vera Sans', 'Liberation Sans', 'Nimbus Sans L', 'FreeSans', 'Helvetica Neue', sans-serif;  
  }
  
  body {
    background: ${({ theme }) => theme.colors.secondaryD1};
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;

  &::-webkit-scrollbar {
    width: 8px !important;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
  }
  }

  body, input, textarea, button {
    font-family: 'Open Sans', Helvetica, Arial, Roboto, Verdana, Tahoma, Geneva, 'DejaVu Sans', 'Bitstream Vera Sans', 'Liberation Sans', 'Nimbus Sans L', 'FreeSans', 'Helvetica Neue', sans-serif;
  }

  ul, li {
    list-style: none;
  }

  button {
    cursor: pointer;
  }
`
