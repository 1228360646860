import { Button } from '@nexds/web'
import styled from 'styled-components'

export const A2hsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 340px;

  left: 50%;
  transform: translateX(-50%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${({ theme }) => theme.colors.neutralL4};

  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  text-align: center;

  z-index: 99999999;
  box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.9);
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 18px;
`

export const TitleIcon = styled.img`
  width: 36px;
  height: 36px;
`

export const TitleText = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;

  color: ${({ theme }) => theme.colors.secondary};
  text-align: left;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  gap: 12px;
`

export const AddButton = styled(Button)`
  padding: 0 24px;
  height: 40px;

  & span {
    ${({ theme }) => theme.fonts.infoMD};
    font-weight: 600;
    padding: 0;
  }
`

export const CloseButton = styled(Button)`
  padding: 0px 24px;
  height: 40px;

  & span {
    ${({ theme }) => theme.fonts.infoMD};
    color: ${({ theme }) => theme.colors.secondary};
    font-weight: 600;
    padding: 0;
  }
`
