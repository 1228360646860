import BackgroundImage from '@assets/background.png'
import BackgroundImage2x from '@assets/background@2x.png'
import BackgroundImage3x from '@assets/background@3x.png'
import { Button } from '@nexds/web'
import styled from 'styled-components'

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  background-image: url(${BackgroundImage});
  background-image: -webkit-image-set(
    url(${BackgroundImage}) 1x,
    url(${BackgroundImage2x}) 2x,
    url(${BackgroundImage3x}) 3x
  );
  background-image: image-set(
    url(${BackgroundImage}) 1x,
    url(${BackgroundImage2x}) 2x,
    url(${BackgroundImage3x}) 3x
  );
  background-image: image-set(
    url(${BackgroundImage}) 1920w,
    url(${BackgroundImage2x}) 3850w,
    url(${BackgroundImage3x}) 5760w
  );

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 835px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;

  gap: 150px;

  @media (max-height: 800px) {
    gap: 100px;
  }

  @media (max-height: 670px) {
    gap: 60px;
  }

  @media (max-height: 500px) {
    gap: 30px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;

  @media (max-height: 800px) {
    gap: 60px;
  }

  @media (max-height: 670px) {
    gap: 50px;
  }

  @media (max-height: 500px) {
    gap: 30px;
  }
`

export const Logo = styled.img`
  width: 300px;

  @media (max-width: 800px) {
    width: 200px;
  }
`

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.h2};
  max-width: 650px;

  @media (max-width: 800px) {
    max-width: 600px;
    ${({ theme }) => theme.fonts.h3};
  }

  @media (max-width: 500px) {
    max-width: 400px;
    ${({ theme }) => theme.fonts.h4};
  }
`

export const Subtitle = styled.h2`
  ${({ theme }) => theme.fonts.titleMD};
  font-weight: 300;
  max-width: 550px;

  @media (max-width: 800px) {
    ${({ theme }) => theme.fonts.titleSM};
    font-weight: 300;
    max-width: 500px;
  }

  @media (max-width: 500px) {
    ${({ theme }) => theme.fonts.titleXS};
    font-weight: 300;
    max-width: 400px;
  }
`

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 60px;
`

export const SearchButton = styled(Button)`
  width: 100%;
  max-width: 160px;
  height: 30px;
  padding: 20px 16px;
  margin: 0 auto;
`

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
`

export const FooterText = styled.span`
  ${({ theme }) => theme.fonts.infoLG};
  color: ${({ theme }) => theme.colors.neutralL4};

  @media (max-width: 400px) {
    ${({ theme }) => theme.fonts.infoSM};
  }
`

export const FooterImage = styled.img`
  width: 180px;

  @media (max-width: 400px) {
    width: 150px;
  }
`
