import { NavigateOptions, useNavigate } from 'react-router-dom'

import qs from 'qs'

import { useAppContext } from '@/context/AppContext'

export const useAppNavigate = () => {
  const navigate = useNavigate()
  const { isEmbedded, isPwa } = useAppContext()

  const navigateTo = (
    to: string,
    params?: Record<string, string | undefined | string[]>,
    options?: NavigateOptions
  ) => {
    const newQueries = qs.stringify(
      { ...params, embedded: isEmbedded ? 'true' : undefined, source: isPwa ? 'pwa' : undefined },
      { skipNulls: true }
    )

    if (newQueries === '') {
      return navigate(to, options)
    } else {
      navigate(`${to}?${newQueries}`, options)
    }
  }

  const customNavigate = (to: string, options?: NavigateOptions) => {
    const splitPath = to.split('?')

    if (splitPath.length === 1) {
      return navigateTo(splitPath[0], {}, options)
    }

    const existingQueries = qs.parse(splitPath[1], { ignoreQueryPrefix: true }) as Record<
      string,
      string | undefined | string[]
    >

    return navigateTo(splitPath[0], existingQueries, options)
  }

  return customNavigate
}
