import styled from 'styled-components'

export const SeparatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Title = styled.div`
  ${({ theme }) => theme.fonts.titleXS}
  display: flex;
  gap: 0.25rem;
  position: relative;
  user-select: none;
  align-items: center;
`

export const HelperButton = styled.button`
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 0.125rem;
  align-items: center;
  display: flex;

  &:focus {
    outline: none;
  }
`

export const HelperTooltip = styled.div`
  ${({ theme }) => theme.fonts.infoXS}
  background: ${({ theme }) => theme.colors.secondaryL2};
  color: ${({ theme }) => theme.colors.neutralL5};
  cursor: default;
  border-radius: 0 0.625rem 0.625rem 0.625rem;
  padding: 12px;
  position: absolute;
  top: 0;
  left: 120%;
  z-index: 1;
  width: 13.75rem;
  height: auto;
  text-align: left;
  box-shadow: -4px 4px 4px 0 rgba(0, 0, 0, 0.25);

  & a {
    ${({ theme }) => theme.fonts.infoXS};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutralL5};
    z-index: 10;
  }
`
