import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import qs from 'qs'

export const useQueryState = <T>(
  query: string,
  fixQtoV: (value: string) => T,
  fixVtoQ: (value: T) => string | null
): [T, (value: T) => void] => {
  const location = useLocation()
  const navigate = useNavigate()

  const setQuery = useCallback(
    (value: T) => {
      const fixedValue = fixVtoQ(value)

      const existingQueries = qs.parse(location.search, {
        ignoreQueryPrefix: true
      })

      let queryString: string

      if (fixedValue === null) {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete existingQueries[query]
        queryString = qs.stringify({ ...existingQueries }, { skipNulls: true })
      } else {
        queryString = qs.stringify({ ...existingQueries, [query]: fixedValue }, { skipNulls: true })
      }

      navigate(`${location.pathname}?${queryString}`, { replace: true })
    },
    [history, location, query]
  )

  const existingValue = qs.parse(location.search, { ignoreQueryPrefix: true })[query] as string

  return [fixQtoV(existingValue ?? ''), setQuery]
}
